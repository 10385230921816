import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import zhResources from "@/lang/zh";
import enResources from "@/lang/en";
import tcResources from "@/lang/tc";
import _ from "lodash";

i18n.use(initReactI18next).init({
  resources: {
    en: enResources,
    zh: zhResources,
    tc: tcResources,
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

export const changeLanguage = (lang) => {
  i18n.changeLanguage(lang);
};

export const getOptionDisplay = (key, value) => {
  const optionsPath = (path) => (path ? `product.options.${path}` : "product.options");
  const optionsList = i18n.t(optionsPath(), { returnObjects: true });
  let result = value;
  if (/^\d+[wm]$/i.test(value)) {
    const cyclePath = optionsPath("cycle");
    const [num, unit] = String(value)
      .replace(/(\d+(?:\.\d+)?)(\D+)/, "$1,$2")
      .split(",");
    result = i18n.t(cyclePath, { context: unit, num });
  }
  const optionsObj = optionsList[key] || optionsList[`${key}Enum`];
  if (!_.isNil(value) && value !== "" && optionsObj) {
    for (const key in optionsObj) {
      if (Object.hasOwnProperty.call(optionsObj, key)) {
        const pattern = String(value).replace(/[ \-]/g, "");
        const reg = new RegExp(pattern, "ig");
        if (reg.test(key)) {
          result = optionsObj[key];
          break;
        }
      }
    }
  }
  return result;
};

export const tl = (text = "", defaultLang) => {
  const languageList = { zh: 0, en: 1 };
  const tranArr = String(text).split("|");
  const lang = defaultLang || i18n.language;
  // const lang = 'zh';
  return tranArr[languageList[lang]] ?? tranArr[0];
};

// language map
export const languageMap = {
  0: "en",
  1: "en",
  2: "en",
  3: "en",
  4: "zh",
  5: "tc",
};
